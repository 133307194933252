import { navigate, graphql } from "gatsby";
import { Button, Form, TextInput } from "carbon-components-react";
import React, { useState } from "react";
import { InlineNotification } from "gatsby-theme-carbon";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
// import recaptchaSubmit from "../util/recaptchaSubmit";
import { siteMetadata } from "../../gatsby-config";

async function jsonRequest(endpoint, method, payload) {
  const url = `${siteMetadata.drupalUrl}/${endpoint}`;
  const init = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetch(url, init);
}

export function SetNewPasswordForm() {
  const [username, setUsername] = useState("");
  const [tempName, setTempName] = useState("");
  const [tempPass, setTempPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  //   const { executeRecaptcha } = useGoogleReCaptcha();

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    switch (name) {
      case "username":
        setUsername(value);
        break;
      case "tempName":
        setTempName(value);
        break;
      case "tempPass":
        setTempPass(value);
        break;
      case "newPass":
        setNewPass(value);
        break;
      case "confirmNewPass":
        setConfirmNewPass(value);
        break;
      default:
        console.log("field has no state");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    setLoading(true);
    try {
      // const valid = await recaptchaSubmit(executeRecaptcha)

      const payload = {
        name: username,
        temp_pass: tempPass,
        new_pass: newPass,
      };

      const response = await jsonRequest(
        "user/lost-password-reset?_format=json",
        "POST",
        payload
      );

      if (response.ok) {
        navigate("/");
      } else {
        setMessage(`Unable to reset password [${response.statusText}]`);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setMessage(`Unable to reset password [${err.message}]`);
    }
  };

  return (
    <>
      <Form className="aupo-login-form" onSubmit={handleSubmit}>
        <h2>Temporary Password Sent</h2>

        <p>
          You should receive an email shortly with a temporary password. Please use it
          here to reset your password
        </p>

        {message && <InlineNotification kind="error">{message}</InlineNotification>}

        <div>
          <TextInput
            light
            required
            type="text"
            name="username"
            id="username"
            labelText="Username:"
            value={username}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <TextInput
            light
            required
            labelText="Temporary Password:"
            type="password"
            name="tempPass"
            id="tempPass"
            value={tempPass}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <TextInput
            light
            required
            labelText="New Password:"
            type="password"
            id="newPass"
            name="newPass"
            value={newPass}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <Button type="submit" disabled={loading}>
            Change password
          </Button>
        </div>
      </Form>
    </>
  );
}

export default function ResetPasswordPage({ pageContext, location }) {
  const [userEmail, setUserEmail] = useState("");
  const [ok, setOk] = useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  //   const { executeRecaptcha } = useGoogleReCaptcha();

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    setUserEmail(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    setLoading(true);
    try {
      //   const valid = await recaptchaSubmit(executeRecaptcha);
      //   if (!valid) throw new Error("Failed recaptcha");
      const payload = { mail: userEmail };
      const response = await jsonRequest(
        "user/lost-password?_format=json",
        "POST",
        payload
      );

      if (response.ok) {
        setOk(true);
      } else {
        setMessage(`Unable to send reset password email [${response.statusText}]`);
      }
      setLoading(false);
    } catch (err) {
      setMessage(err.message);
      setLoading(false);
    }
  };

  return (
    <>
      {!ok ? (
        <>
          <Form
            className="aupo-login-form"
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                let handle = await handleSubmit(e);
              } catch (err) {
                setMessage(err.message);
              }
            }}
          >
            <h2>Password Reset</h2>
            <p>
              Enter email associated with the account you would like to password reset
            </p>
            {message && <InlineNotification kind="error">{message}</InlineNotification>}
            <div>
              <TextInput
                id="user-email"
                light
                required
                type="text"
                required
                labelText="Email"
                name="userEmail"
                id="userEmail"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Button type="submit" disabled={loading}>
                Reset Password
              </Button>
            </div>
          </Form>
        </>
      ) : (
        <SetNewPasswordForm />
      )}
    </>
  );
}

export const query = graphql`
  {
    allSitePage(filter: { path: { eq: "/password-reset" } }) {
      edges {
        node {
          id
          path
          fields {
            frontmatter {
              description
              keywords
              tabs
              title
            }
          }
        }
      }
    }
  }
`;
