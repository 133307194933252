const languages = require("./src/data/languages");

module.exports = {
  siteMetadata: {
    title: "AUPO Medical Student Exam Question Bank",
    description: "Association of University Professors of Ophthalmology Question Bank",
    keywords: "gatsby,theme,carbon",
    drupalUrl: "https://msequestionbank-prod-backend.parallelpublicworks.com",
    langs: languages,
  },
  pathPrefix: `/aupoqb`,
  plugins: [
    {
      resolve: "gatsby-plugin-manifest",
      options: {
        name: "AUPO Medical Student Exam Question Bank",
        short_name: "AUPO Medical Student Exam Question Bank",
        start_url: "/",
        background_color: "#f7f9f5",
        theme_color: "#252e64",
        display: "browser",
        icon: "./src/images/favicon.svg",
      },
    },
    {
      resolve: "gatsby-source-drupal",
      options: {
        baseUrl: "https://msequestionbank-prod-backend.parallelpublicworks.com",
        langs: languages,
      },
    },
    {
      resolve: "gatsby-theme-carbon",
      options: {
        mediumAccount: "carbondesign",
        iconPath: "./src/images/favicon.svg",
        repository: {
          baseUrl: "https://github.com/carbon-design-system/gatsby-theme-carbon",
          subDirectory: "/packages/example",
        },
        navigationStyle: "header",
        isSearchEnabled: false,
        theme: {
          homepage: "g10",
          interior: "g10",
        },
      },
    },
  ],
};
